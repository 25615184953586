<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Etiquetas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">POR FAVOR, SELECCIONE LAS ETIQUETAS</span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class="my-2 icon-custom">
                            <b-form-checkbox-group plain v-model="selectedEtiquetas" :options="comboEtiquetas" class="mr-1" value-field="idEtiqueta" text-field="nombre" disabled-field="notEnabled"></b-form-checkbox-group>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de etiquetas</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">

                        <b-col md="4" class="my-2">
                            <b-button block size="md" :to="{name: 'Banco de normas'}" variant="custom">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br>Volver
                            </b-button>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Documentos registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaDocumentos" :fields="campoDocumentos" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(nombre)="data">
                                    <a :href="`${data.item.urlArchivo}`" target="_blank" style="text-decoration: none;">
                                        <i class="fas fa-file-alt"></i> <span>{{data.item.nombre}}</span>
                                    </a>
                                </template>
                                <template #cell(opciones)="param">
                                    <b-button v-if="checkPermissions('001-DOC-BAN-DOC','u') == 1" @click="abrirmodalActualizarDocumento(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                        <i class="fas fa-search  my-0 mx-0"></i>
                                    </b-button>
                                    <b-button v-if="checkPermissions('001-DOC-BAN-DOC','d') == 1" @click="eliminarDocumento(param)" size="sm" class="mr-1 mb-1" variant="danger" v-c-tooltip="'Eliminar'">
                                        <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(fechaCreacion)="param">
                                    <span>{{param.item.fechaCreacion.substr(0,10)}}</span>
                                </template>
                                <template #cell(fechaModificacion)="param">
                                    <span>{{param.item.fechaModificacion.substr(0,10)}}</span>
                                </template>
                                <template #cell(categorias)="param">
                                    <span class="h6" :key="i.index" v-for="i in param.item.categorias.split(',')">
                                        <b-badge variant="dark" class="mr-1">
                                            {{i.split('xdz')[1]}}
                                        </b-badge>
                                    </span>
                                </template>
                                <template #cell(etiquetas)="param">
                                    <span class="h6" :key="i.index" v-for="i in param.item.etiquetas.split(',')">
                                        <b-badge :style="`background: ${i.split('xdz')[2]};color: #ffffff;`" class="mr-1">
                                            <i class="fas fa-tag fa-sm"></i>
                                            {{i.split('xdz')[1]}}
                                        </b-badge>
                                    </span>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>

    <CModal :closeOnBackdrop="false" size="lg" color="primario" :show.sync="modalActualizarDocumento">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar documento</span></h6>
            <CButtonClose @click="modalActualizarDocumento = false" class="text-white" />
        </template>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarDocumento)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input type="text" :state="getValidationState(validationContext)" size="md" placeholder="Ingrese el nombre" v-model="datosActualizarDocumento.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fechaCreacion" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de creación:" class="mb-2">
                                <b-form-input type="date" :state="getValidationState(validationContext)" size="md" v-model="datosActualizarDocumento.fechaCreacion"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="decripción" :rules="{required: true}">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" size="md" placeholder="Ingrese la descripción" v-model="datosActualizarDocumento.descripcion"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="archivo" v-slot="validationContext">
                            <b-form-group label="Archivo:" class="mb-2">
                                <b-input-group>
                                    <b-input-group-prepend v-if="datosActualizarDocumento.urlArchivo != ''">
                                        <b-button variant="dark" class="px-2 py-0" v-c-tooltip="'Descargar'" @click="descargarDocumento(datosActualizarDocumento.urlArchivo)">
                                            <i class="fas fa-download fa-xs"></i>
                                        </b-button>
                                    </b-input-group-prepend>
                                    <b-form-file ref="filex" v-model="datosActualizarDocumento.archivoMeta" :state="(getValidationState(validationContext) )" :placeholder="datosActualizarDocumento.nombreArchivo == '' ?  'Elija un archivo o arrástrelo aquí...' : datosActualizarDocumento.nombreArchivo" :accept="accept.toString()" browse-text="Subir" v-on:change="handleActualizarFileUpload"></b-form-file>
                                </b-input-group>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="categorías" rules="required" v-slot="{errors}">
                            <b-form-group label="Categorías:" class="mb-2">
                                <v-select :reduce="comboCategorias =>comboCategorias.idCategoriaNorma" multiple label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.categorias.length > 0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.categorias" :options="comboCategorias" @search:blur="blurCategorias">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="etiquetas" rules="required" v-slot="{errors}">
                            <b-form-group label="Etiquetas:" class="mb-2">
                                <v-select :reduce="comboEtiquetas =>comboEtiquetas.idEtiqueta" multiple label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarDocumento.etiquetas.length > 0 , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarDocumento.etiquetas" :options="comboEtiquetas" @search:blur="blurEtiquetas">
                                    <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col class="my-2 text-right" md="12">
                        <b-button size="md" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button size="md" variant="danger" @click="modalActualizarDocumento = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>

        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
export default {
    data() {
        return {
            disabledFile: false,
            selectedEtiquetas: [],
            comboCategorias: [],
            comboEtiquetas: [],
            modalActualizarDocumento: false,
            totalRows: 1,
            currentPage: 1,
            filter: '',
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoDocumentos: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "nombre",
                    label: "Nombre",
                    class: "text-center",
                },
                {
                    key: "fechaCreacion",
                    label: "Fecha de creación",
                    class: "text-center",
                },
                {
                    key: "nombrePersona",
                    label: "Autor",
                    class: "text-center",
                },
                {
                    key: "categorias",
                    label: "Categorías",
                    class: "text-center",
                },
                {
                    key: "etiquetas",
                    label: "Etiquetas",
                    class: "text-center",
                },
                {
                    key: "fechaModificacion",
                    label: "Última modificación",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaDocumentos: [],
            datosActualizarDocumento: {
                idCliente: '',
                idUsuario: '',
                idDocumento: '',
                archivoMeta: null,
                urlArchivo: '',
                nombreArchivo: '',
                nombre: '',
                descripcion: '',
                fechaCreacion: '',
                categorias: [],
                etiquetas: []
            },
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]
        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        handleActualizarFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["filex"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["filex"].reset();
                    return;
                }
                this.datosActualizarDocumento.archivoMeta = this.$refs.filex.files[0];
            }
        },
        descargarDocumento(url) {
            window.open(url)
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        actualizarDocumento() {
            let me = this;
            me.disabledFile = true;

            const formData = new FormData();

            formData.append("file", me.datosActualizarDocumento.archivoMeta);
            formData.append("folder", 'documentacion/ver-documentos-coordinador');

            formData.append("idDocumentoNorma", me.datosActualizarDocumento.idDocumentoNorma);

            formData.append("idCliente", me.datosActualizarDocumento.idCliente);
            formData.append("idUsuario", me.datosActualizarDocumento.idUsuario);

            formData.append("urlArchivo", me.datosActualizarDocumento.urlArchivo);
            formData.append("nombreArchivo", me.datosActualizarDocumento.nombreArchivo);

            formData.append("nombre", me.datosActualizarDocumento.nombre);
            formData.append("descripcion", me.datosActualizarDocumento.descripcion);
            formData.append("fechaCreacion", me.datosActualizarDocumento.fechaCreacion);
            formData.append("categorias", me.datosActualizarDocumento.categorias);
            formData.append("etiquetas", me.datosActualizarDocumento.etiquetas);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-documento-norma",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalActualizarDocumento = false;
                    me.listarDocumentos();
                    me.disabledFile = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabledFile = false;
                });
        },
        eliminarDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-documento-norma", {
                                idDocumentoNorma: param.item.idDocumentoNorma
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarDocumentos();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        abrirmodalActualizarDocumento(param) {
            let me = this;
            me.datosActualizarDocumento.idDocumentoNorma = param.item.idDocumentoNorma;
            me.datosActualizarDocumento.urlArchivo = param.item.urlArchivo;
            me.datosActualizarDocumento.nombreArchivo = param.item.nombreArchivo;
            me.datosActualizarDocumento.nombre = param.item.nombre;
            me.datosActualizarDocumento.fechaCreacion = param.item.fechaCreacion.substr(0, 10);
            me.datosActualizarDocumento.descripcion = param.item.descripcion;
            for (let i of param.item.categorias.split(',')) {
                me.datosActualizarDocumento.categorias.push(i.split('xdz')[0])
            }
            for (let i of param.item.etiquetas.split(',')) {
                me.datosActualizarDocumento.etiquetas.push(i.split('xdz')[0])
            }
            me.modalActualizarDocumento = true;
        },
        resetModalActualizarDocumento() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosActualizarDocumento.categorias = [];
            this.datosActualizarDocumento.etiquetas = [];
        },
        listarEtiquetas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-etiquetas-normas", {
                        params: {
                            idCliente: me.datosActualizarDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboEtiquetas = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarCategorias() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-categorias-normas", {
                        params: {
                            idCliente: me.datosActualizarDocumento.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.comboCategorias = response.data;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarDocumentos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/busqueda-documentos-normas", {
                        params: {
                            idCliente: me.datosActualizarDocumento.idCliente,
                            filtro: me.selectedEtiquetas
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentos = response.data
                    me.totalRows = me.listaDocumentos.length;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },

        blurCategorias() {
            this.computedForm.refs.categorías.validate();
        },
        blurEtiquetas() {
            this.computedForm.refs.etiquetas.validate();
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        }
    },
    watch: {
        modalActualizarDocumento: function (val) {
            if (val == false) {
                this.resetModalActualizarDocumento();
            }
        },
        'selectedEtiquetas': function (val) {
            this.listarDocumentos();
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosActualizarDocumento.idCliente = user.uidClient;
            this.datosActualizarDocumento.idUsuario = user.uid;
            this.listarDocumentos();
            this.listarEtiquetas();
            this.listarCategorias();
        }
    }

}
</script>
